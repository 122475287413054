// Generated by Framer (21ec140)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./o20NHij70-0.js";

const cycleOrder = ["YkUhfyd_V", "LJBKEJ7JP", "ugeeIrkbL"];

const serializationHash = "framer-okMSb"

const variantClassNames = {LJBKEJ7JP: "framer-v-18b432z", ugeeIrkbL: "framer-v-qqrkbn", YkUhfyd_V: "framer-v-rn0390"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {IGeMnN5IH: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 30, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 3": "ugeeIrkbL", Green: "YkUhfyd_V", Yellow: "LJBKEJ7JP"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "YkUhfyd_V"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "YkUhfyd_V", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (["LJBKEJ7JP", "ugeeIrkbL"].includes(baseVariant)) return false;
return true
}

const isDisplayed1 = () => {
if (baseVariant === "LJBKEJ7JP") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rn0390", className, classNames)} data-framer-name={"Green"} layoutDependency={layoutDependency} layoutId={"YkUhfyd_V"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({LJBKEJ7JP: {"data-framer-name": "Yellow"}, ugeeIrkbL: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<SVG className={"framer-1vxzo0y"} data-framer-name={"GreenCircle"} fill={"black"} intrinsicHeight={20} intrinsicWidth={20} layoutDependency={layoutDependency} layoutId={"c6uG21zeg"} svg={"<svg width=\"20\" height=\"20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M10 0c5.565 0 10 4.3 10 10.018C20 15.7 15.565 20 10 20c-5.602 0-10-4.296-10-9.982C0 4.3 4.398 0 10 0Zm0 4.86c-2.972 0-5.413 1.983-5.413 5.158 0 3.14 2.446 5.122 5.413 5.122s5.413-1.978 5.413-5.122c0-3.175-2.446-5.158-5.413-5.158Z\" fill=\"#6BC047\"/></svg>"} withExternalLayout/>)}{isDisplayed1() && (<SVG className={"framer-1dhim9q"} data-framer-name={"YellowCircle"} fill={"black"} intrinsicHeight={20} intrinsicWidth={20} layoutDependency={layoutDependency} layoutId={"GihIsj8LJ"} svg={"<svg width=\"20\" height=\"20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M10 0c5.565 0 10 4.3 10 10.018C20 15.7 15.565 20 10 20c-5.602 0-10-4.296-10-9.982C0 4.3 4.398 0 10 0Zm0 4.86c-2.972 0-5.413 1.983-5.413 5.158 0 3.14 2.446 5.122 5.413 5.122s5.413-1.978 5.413-5.122c0-3.175-2.446-5.158-5.413-5.158Z\" fill=\"#FFD635\"/></svg>"} withExternalLayout/>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-okMSb.framer-kefjl5, .framer-okMSb .framer-kefjl5 { display: block; }", ".framer-okMSb.framer-rn0390 { height: 20px; mix-blend-mode: multiply; overflow: hidden; position: relative; width: 20px; }", ".framer-okMSb .framer-1vxzo0y, .framer-okMSb .framer-1dhim9q { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 20px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LJBKEJ7JP":{"layout":["fixed","fixed"]},"ugeeIrkbL":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framero20NHij70: React.ComponentType<Props> = withCSS(Component, css, "framer-okMSb") as typeof Component;
export default Framero20NHij70;

Framero20NHij70.displayName = "GreenCircle";

Framero20NHij70.defaultProps = {height: 20, width: 20};

addPropertyControls(Framero20NHij70, {variant: {options: ["YkUhfyd_V", "LJBKEJ7JP", "ugeeIrkbL"], optionTitles: ["Green", "Yellow", "Variant 3"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framero20NHij70, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})